<template>
    <div id="app">

      <!-- <div v-if="$store.isDebug">
        <div>{{ modelSrc }}</div>
        <div>{{ iosSrc }}</div>
      </div> -->

    <model-viewer 
            ref="modelViewer"
            alt="house" 
            :src="modelSrc" 
            :ios-src="iosSrc"
            ar 
            ar-modes="scene-viewer quick-look"
            shadow-intensity="1" 
            camera-controls 
            touch-action="pan-y"
            style="width: 300px; 
            height: 300px;">
          </model-viewer>
    
    </div>
    </template>

    <script>
    
    export default {
      props: ['shortCode'],
      name: 'App',
      data: function () {
        return{
          name: "ModelViewer",
          canActivate:false,
          isMobile:false,
          modelSrc:"",
          iosSrc: null 
        }
      },
      components: {
      },
      created:function(){

          this.getUrl();

      },
      mounted:function(){
        this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if(this.isMobile){
            this.startTimer();
        }

      },
      methods: {
        isUSDZFile(filename) {
          return filename.toLowerCase().endsWith('.usdz');
        },
        startTimer() {
            this.timer = setInterval(this.checkCanActivateAR, 1000);
        },
        checkCanActivateAR() {       
            this.canActivate = this.$refs.modelViewer.canActivateAR;

            if (this.canActivate) {
                clearInterval(this.timer);
                this.$refs.modelViewer.activateAR();
            }
        },
         async getUrl() {
            const response = await fetch(`${this.$store.apiUrl}/geturl/${this.shortCode}`)
            .then(response => response.json())
            .then(data => {

              this.modelSrc = data.url;
              this.iosSrc = data.iosUrl;
   
              var name = this.$utils.extractFileName(data.url);
              this.$utils.siteLog("ARViewer", name, data.project);
            })
            .catch(error => {
              console.error('Fout bij het ophalen van gegevens:', error);
            });
    }
      },
      computed: {
     }
    }
    
    </script>
    
    <style>
    </style>
    