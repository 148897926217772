<template>
  <div id="app">

    <div v-if="this.$store.state.isAdmin" class="ontop">
      
      <b-button @click="setrotation">set rotation</b-button>
 
      <p>
        <b-dropdown :text="currentProject || 'Selecteer project...'" class="custom-dropdown" variant="primary">
              <b-dropdown-item 
                v-for="(project, index) in this.$store.state.projects"
                :key="index" 
                @click="updateProject($route.params.shortcode, project.PartitionKey)">
                {{ project.PartitionKey }}
              </b-dropdown-item>
      </b-dropdown>
      </p>

    </div>

    <a-scene v-if="fileurl != ''" @loaded="onSceneLoaded" xr-mode-ui="enabled: false">
      <a-entity camera look-controls ref="camera" ></a-entity>
      <a-sky :src="fileurl" :rotation="skyRotation"></a-sky>
    </a-scene>

  </div>  
</template>

<script>
import 'aframe';

export default {
  name: 'App',
  data() {
    return {
      fileurl: '',
      skyRotation: '0 0 0',
      touchEnabled: true,
      currentProject:""
    };
  },
  mounted: async function(){
    await this.getUrl();
    this.$utils.siteLog("360 view", this.$utils.extractFileName(this.fileurl), this.currentProject);
  },
  methods: {

    async updateProject(shortcode, project){
      this.currentProject = project;
        var success = await this.$utils.AddProjectToShortUrl(shortcode, project);
        if(success) this.$utils.showToast('Image set to Project: ' + project, 'Success', 'success');

    },
    onSceneLoaded() {
      this.getImageRotation();
      this.setTouchEnabled(this.$store.state.isAdmin);
    },
    setrotation(){
      var rot =this.$refs["camera"].getAttribute('rotation');
      var rotationString = `0 ${-rot.y} 0`;
      this.saveImageRotation(rotationString);
    },
    async getUrl() {
            await fetch(`${this.$store.apiUrl}/geturl/${this.$route.params.shortcode}`)
            .then(response => response.json())
            .then(data => {
              this.currentProject = data.project;
              this.fileurl = data.url;
            })
            .catch(error => {
              console.error('Fout bij het ophalen van gegevens:', error);
            });
    },
    async getImageRotation() {
    fetch(`${this.$store.apiUrl}/getimagerotation/${this.$route.params.shortcode}`)
        .then(response => {
            if (response.status === 404) {
              return;
            }
            return response.json();
        })
        .then(data => {
            if (data) {
                this.skyRotation = data.rotation;
            }
        })
        .catch(error => {
            console.error('Fout bij het ophalen van gegevens:', error);
        });
    }     
    ,
    async saveImageRotation(rotation) {
      const entity = {
          clientId: this.$store.clientId,
          PartitionKey: this.$route.params.shortcode,
          Rowkey: rotation
      };

      try {

          const response = await fetch(`${this.$store.apiUrl}/saveimagerotation`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(entity)
          });

          if(response.ok){
            const data = await response.text();
            this.$utils.showToast('Record saved successfully!', 'Success', 'success');
          }
      } catch (error) {
          console.error('Fout bij het opslaan van de gegevens:', error);
      }
},
    setTouchEnabled(enabled) {
      const cameraEl = this.$refs["camera"];
      cameraEl.setAttribute('look-controls', `touchEnabled: ${enabled}`);
    }
  },
  computed: {
  }
  
};
</script>

<style>
#app {
  position: relative;
}

.ontop {
  z-index: 10;
  color:#fff;
  position: absolute;
  left: 20px;
  top:20px;
}

.custom-dropdown{

}

</style>
